<template>
   <div class="content">
     <div class="row">

 <div class="col-md-12">
<vuestic-widget :headerText="$t('view.staff.title')">
  <p>{{$t('view.staff.description')}}</p>
   <div class="row">

        <div class="col-md-3">
           <button type="button" v-on:click="back" class="btn btn-primary btn-sm"> {{$t('view.staff.back')}}</button>
        </div>

          <div class="col-md-3 offset-md-6" v-show="statusEvent === 1">
            <button type="button" v-on:click="newStaff" class="btn btn-primary btn-sm">{{$t('view.staff.new')}}</button>
        </div>
   </div>
</vuestic-widget>
 </div>
            <div class="col-md-12">
      <table-staff></table-staff>

        </div>
     </div>
   </div>
</template>

<script>
import Vue from 'vue'
import TableStaff from '../tables/TableStaff/TableStaff.vue'

import { SpringSpinner } from 'epic-spinners'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'Table',
  components: {
    SpringSpinner,
    TableStaff
  },
  data () {
    return {

    }
  },
  methods: {
    back () {
      this.$router.replace('/event/dashboard')
    },
    newStaff () {
      this.$router.push('staff/new')
    }

  },
  computed: {
    ...mapState({
      statusEvent: state => state.event.event.status
    }),
  },
}
</script>

<style lang="scss">

  .color-icon-label-table {
    td:first-child {
      width: 1rem;
    }
  }
</style>
